import * as React from "react"
import { Layout } from "../components/layout/layout"
import * as styles from "./404.module.css"
import { Builder } from "@builder.io/react"
import { PFBuilderComponent } from "../builder"
import Btn from "../components/material/btn/btn"

const POSSIBLE_GIFS = [
    'https://media.giphy.com/media/zdPTU3dZpC2zuxHijE/giphy.gif',
    'https://media.giphy.com/media/2whaYPSovcBctxt78k/giphy.gif',
    'https://media.giphy.com/media/ZaKcIYMjNYNf4lEuC7/giphy.gif'
];

export default function NotFoundPage() {

    const shouldLoadBuilder = Builder.isPreviewing || Builder.isEditing;
    const gifUrl = POSSIBLE_GIFS[Math.round(Math.random() * POSSIBLE_GIFS.length - 1)];

    return <Layout>

        {shouldLoadBuilder
            ? <PFBuilderComponent model="page" />
            : <main className={styles.content}>
                <div className={styles.code}>
                    404
                </div>
                <div className={styles.title}>
                    Looks like you're lost.
                </div>
                <div className={styles.gifContainer}>
                    <img src={gifUrl} width='100%' />
                </div>
                <div className={styles.subtitle}>
                    It's okay to lose sight of your purpose every once in a while — it happens to the best of us.
                    <br/>
                    Let's just make sure you get back on track as soon as possible!
                </div>
                <div className={styles.links}>
                    <Btn href='/' className='block bg-black text-slate-100 text-center h-12 text-lg' icon='bi-house-heart-fill'>
                        Return Home
                    </Btn>
                </div>
            </main>}

    </Layout>
}
